import React, { useState } from "react"
import { Tab, Tabs, Box, Heading, Markdown } from "grommet"

import { themeExtension } from "../../styles/theme"

import Table from "./Table"

const RichTabTitle = ({ label, first, last, active }) => (
  <Box
    background={active ? "brand" : "white"}
    direction="row"
    align="center"
    pad={{ horizontal: "medium", vertical: "small" }}
    border={{
      color: "brand",
      size: "xsmall",
      side: "all",
    }}
    style={
      first
        ? {
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
          }
        : last
        ? {
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
          }
        : null
    }
  >
    {label}
  </Box>
)

const PricingTabs = ({ data }) => {
  const [index, setIndex] = useState(0)
  const onActive = nextIndex => setIndex(nextIndex)

  const styledTab = {
    borderRadius: "12px",
    boxShadow: "rgba(90, 90, 90, 0.1) 0px 18px 28px",
  }

  return (
    <Box
      align="center"
      direction="column"
      pad={{
        bottom: "xlarge",
        horizontal: "medium",
      }}
      background={themeExtension.colors.backgrounds.light}
    >
      <Box
        direction="column"
        pad="medium"
        width="xlarge"
        justify="center"
        margin={{ top: "-100px" }}
      >
        <Box style={{ width: "100%" }}>
          <Box
            background="white"
            style={{
              boxShadow: themeExtension.shadows.graySoft,
              zIndex: 2,
            }}
            round="small"
          >
            <Box pad={{ top: "large", horizontal: "large" }}>
              <Heading
                level="2"
                textAlign="center"
                alignSelf="center"
                color="#4F4F4F"
                margin={{
                  vertical: "xsmall",
                  horizontal: "0",
                }}
              >
                {data && data.sectionHeader}
              </Heading>

              <Heading
                level={3}
                color="dark-3"
                alignSelf="center"
                textAlign="center"
                margin={{ top: "xsmall", bottom: "medium" }}
                style={{ maxWidth: "100%", fontWeight: "600" }}
              >
                {data && (
                  <Markdown
                    components={{
                      h3: {
                        component: "Heading",
                        props: { color: "dark-3" },
                      },
                    }}
                  >
                    {data.sectionSubheading}
                  </Markdown>
                )}
              </Heading>
            </Box>

            <Tabs activeIndex={index} onActive={onActive}>
              <Tab
                plain
                title={
                  <RichTabTitle
                    label="Spoor"
                    first={true}
                    active={index === 0}
                  />
                }
                margin={{ bottom: "large" }}
                style={styledTab}
              >
                <Table data={data && data.spoor} />
              </Tab>

              <Tab
                plain
                title={
                  <RichTabTitle
                    label="Special Cargo"
                    last={true}
                    active={index === 1}
                  />
                }
                margin={{ bottom: "large" }}
                style={styledTab}
              >
                <Table data={data && data.specialCargo} />
              </Tab>
            </Tabs>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PricingTabs
